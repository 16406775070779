// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '834a2458f763db0b1d6ad2b8a6ab9d00';
const css =`._popoverWrapper_li2jv_1{z-index:600}._defaultPopover_li2jv_1{--tw-border-opacity:1;--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity));border-color:rgb(221 221 221/var(--tw-border-opacity));border-radius:.25rem;border-width:1px;min-width:7rem;padding:.5rem 1rem}._defaultMenu_li2jv_1{cursor:pointer;padding:0}._defaultMenuList_li2jv_1:focus{outline:2px solid transparent;outline-offset:2px}._defaultMenuItem_li2jv_1{--tw-border-opacity:1;align-items:center;border-color:rgb(221 221 221/var(--tw-border-opacity));border-top-width:1px;display:flex;flex-direction:row;margin:0;padding:.5rem .75rem;text-align:left;width:100%}._defaultMenuItem_li2jv_1:first-child{border-top-width:0}._defaultMenuItem_li2jv_1:hover{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}._defaultMenuItem_li2jv_1 svg{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity));height:1.25rem;margin-right:.5rem;transform:translateY(.06rem);width:1.25rem}._defaultMenuItemActive_li2jv_1{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}`;
const styles = {
    get ['popoverWrapper']() { injectStyles(key, css, options);  return '_popoverWrapper_li2jv_1'; },
    get ['defaultPopover']() { injectStyles(key, css, options);  return '_defaultPopover_li2jv_1'; },
    get ['defaultMenu']() { injectStyles(key, css, options);  return '_defaultMenu_li2jv_1'; },
    get ['defaultMenuList']() { injectStyles(key, css, options);  return '_defaultMenuList_li2jv_1'; },
    get ['defaultMenuItem']() { injectStyles(key, css, options);  return '_defaultMenuItem_li2jv_1'; },
    get ['defaultMenuItemActive']() { injectStyles(key, css, options);  return '_defaultMenuItemActive_li2jv_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
