import { useEffect } from 'react'
import useAuth from '../contexts/auth'

export function UnthreadWidget() {
  const { self } = useAuth()

  useEffect(() => {
    if (self?.user?.id) {
      window.$unthread?.inAppChat('start', {
        user: {
          name: self.user.name,
          email: self.user.email,
        },
      })
    }
  }, [self?.user?.id])

  return null
}
